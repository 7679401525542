.card {
    .inner {
        .right {
            .title,
            .desc {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden; 
            }
        }
    }

    &:hover {
        box-shadow: 0 0 50px rgba(0,0,0,0.1);

        .title {
            color: var(--color-secondary-dark);
        }

        .button.fancy span {
            color: var(--color-secondary-dark);
            border-color: var(--color-secondary-dark);
        }
        .button.fancy svg {
            margin-left: 8px;
        }
        .button.fancy svg path {
            fill: var(--color-secondary-dark);
        }
    }


    &.card-style-1 {
        .inner {
            grid-template-columns: 1.5fr 2fr;
        }
    }
    &.card-style-3 {
        .buttons {
            grid-template-columns: 1fr auto;
        }
    }
}


.progress {
    grid-template-columns: 1fr auto;
    align-items: center;

    .track {
        border-radius: 100px;
        box-shadow: 0 0 10px rgba(0,0,0,.2);
        padding: 4px;
        position: relative;
        width: 100%;

        .complete {
            background-color: var(--color-secondary-dark);
            border-radius: 100px;
            display: block;
            height: 5px;
            width: var(--complete-percent);
        }
    }
}


.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: all;
    }

    .modal-inner {
        background-color: #fff;
        border-radius: 30px;
        max-width: 70vw;
        max-height: 90vh;
        width: 100%;
        height: auto;
        padding: 30px;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 0;
        }

        .teacher-list {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
            margin: 50px 0;

            .teacher {
                padding: 20px;
                border-radius: 20px;
                box-shadow: 0 0 10px rgba(0,0,0,0.1);
                transition: 0.2s;
                cursor: pointer;
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 50px;
                align-items: center;

                .left {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .icon {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        svg {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }

                &.selected,
                &:hover {
                    background-color: rgba(0,0,0,0.1);
                }
            }
        }
    }
}