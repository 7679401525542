@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Single+Day&display=swap');

:root {
  --color-primary-light: #4B6BA4;
  --color-primary-dark: #341F58;
  --color-secondary-light: #DDF1A3;
  --color-secondary-dark: #7CCAA5;
  --color-text-heading: #121212;
  --color-text-body: #363636;
  --color-warning: #C90808;

  --font-heading: 'Single Day', cursive;
  --font-body: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text-body);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.button {
  display: inline-flex;
  cursor: pointer;
  font-size: 24px;
  font-family: var(--font-heading);
  align-items: center;
  transition: 0.2s;
}

.button span {
  padding: 8px 16px;
  transition: 0.2s;
}
.button:hover span {
  color: var(--color-warning);
  border-color: var(--color-warning);
}
.button svg {
  position: relative;
  margin-left: -8px;
  transition: 0.2s;
}
.button svg path {
  transition: 0.2s;
}
.button:hover svg {
  margin-left: 8px;
}
.button:hover svg path {
  fill: var(--color-warning);
}

.button.fancy span {
  border-bottom: 2px dashed var(--color-text-body);
  padding: 8px 16px;
  transition: 0.2s;
}
.button.fancy:hover span {
  color: var(--color-secondary-dark);
  border-color: var(--color-secondary-dark);
}
.button.fancy svg {
  position: relative;
  margin-left: -8px;
  transition: 0.2s;
}
.button.fancy svg path {
  transition: 0.2s;
}
.button.fancy:hover svg {
  margin-left: 8px;
}
.button.fancy:hover svg path {
  fill: var(--color-secondary-dark);
}


.button.fancy.small,
.button.small {
  font-size: 16px;
}
.button.fancy.small svg,
.button.small svg {
  width: 20px;
  height: 20px;
}
.button.fancy.small:hover svg,
.button.small:hover svg {
  margin-left: 0;
}