.single-survey-page-wrapper {
    .inner-wrapper {
        grid-template-columns: 2fr 1fr;

        .left {
            .options {
                grid-template-columns: 1fr 1fr;

                .answer {
                    text-align: center;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

                    &.active,
                    &:hover {
                        background-color: var(--color-secondary-light);
                    }
                }
            }
        }


        .right {
            .question-list {
                height: 700px;
                overflow-x: hidden;
                overflow-y: scroll;
                padding-right: 12px;

                .question-item {
                    grid-template-columns: auto 1fr;

                    &.active {
                        background-color: var(--color-secondary-dark)!important;
                    }

                    &.hasAnswer {
                        background-color: #DDF1A3;
                    }
                }
            }
        }
    }
}