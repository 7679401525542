.loaderWrapper {
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.575);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    display: flex;
    justify-content: center;
    align-items: center;

    &.pageLoader {
        height: 100vh;
        width: 100vw;
        z-index: 9999999999;
    }

    .loader {
        border: 4px solid #131212;
        /* Light grey */
        border-top: 4px solid #cee48b;
        /* Blue */
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 0.5s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}