.login-page-wrapper {
    background-image: linear-gradient(252.44deg, rgba(124, 202, 165, 0.5) 0%, rgba(221, 241, 163, 0.5) 100%);

    .inner-wrapper {
        min-height: 600px;
        box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);

        .left {
            .social-icons {
                svg path {
                    transition: 0.2s;
                }
                .item:hover {
                    svg path {
                        stroke: var(--color-secondary-dark);
                    }
                }
            }
        }

        .right {
            .form-wrapper {
                .input-group {
                    .form-control {
                        &:-webkit-autofill,
                        &:-webkit-autofill:hover, 
                        &:-webkit-autofill:focus, 
                        &:-webkit-autofill:active{
                            -webkit-box-shadow: 0 0 0 100px white inset !important;
                        }
                    }
                    .icon {
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}