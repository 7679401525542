.dashboard-page-wrapper {
    .tabs {
        .tab {
            border-bottom: 3px solid transparent;
            transition: 0.2s;

            span {
                transition: 0.2s;
            }
            svg {
                width: 32px;
                height: 32px;
                transition: 0.2s;
            }

            &:hover {
                opacity: 1;

            }
            
            &.active {
                opacity: 1;
                border-color: var(--color-secondary-dark);

                span {
                    color: var(--color-secondary-dark);
                }
                svg path {
                    fill: var(--color-secondary-dark);
                }
            }
        }
    }


    .tab-contents {
        .survey-category {
            .content-title {
                position: sticky;
                top: 100px;
                z-index: 9;
            }
            
            .content-inner {
                height: 0;
                overflow: hidden;
            }
            
            &.open {
                .content-title {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
                .content-inner {
                    height: auto;
                }
            }
        }
    }
}




.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: all;
    }

    .modal-inner {
        background-color: #fff;
        border-radius: 30px;
        max-width: 70vw;
        max-height: 90vh;
        width: 100%;
        height: auto;
        padding: 30px;

        .form-group {
            &.error {
                label {
                    color: red;
                }

                .form-control {
                    border-color: red;

                    &::placeholder {
                        color: red;
                    }
                }

                .errorText {
                    font-weight: bold;
                    color: red;
                    font-size: 12px;
                }
            }
        }
    }
}